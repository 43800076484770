import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import cn from 'classnames'
import Link from 'next/link'
import Image from 'next/image'
import styles from './FooterNew.module.scss'
import { SHOW_BLOG } from '../../../constants/constants'
import ModalWindow from '../ModalWindow/ModalWindow'
import {
  aboutItemData,
  appData,
  customerItemData,
  followUsData,
  shopItemData
} from './staticData'
import useWindowDimensions from "../../../customHooks/useWindowDimension";

export const FooterNew = () => {
  const [disclaimerModalOpened, setDisclaimerModalOpened] = useState(false)
  const [openBlock, setOpenBlock] = useState(false);
  const { width } = useWindowDimensions()

  const onClickButton = () => {
    setOpenBlock(true);
    if (openBlock === true) {
      setOpenBlock(false);
    }
  }

  const { t } = useTranslation('footer')
  return (
    <footer className={styles.siteFooter}>
      <div className={styles.container}>
        <div className={cn(styles.navNew, styles.mobile, styles.followBlock)}>
          <p className={styles.titleBlock}>Follow us</p>
          <ul className={cn(styles.list, styles.listIcon)}>
            {followUsData.map((item)=> {
              return (
                  <>
                    <li key={item.id} className={styles.item}>
                      <a target="_blank" rel="noopener noreferrer" href={item.link}>
                        <Image
                            src={item.img}
                            alt="social"
                            width={40}
                            height={40}
                            objectFit="contain"
                        />
                      </a>
                    </li>
                  </>
              )
            })}
          </ul>
        </div>
        <div className={cn(styles.block, styles.blockMobile)}>
          <div className={styles.navNew}>
            <p className={cn(
                styles.titleBlock,
                {[styles.plusBlockOpen]:openBlock===false},
                {[styles.plusBlock]:openBlock===true})}
               onClick={onClickButton}
            >
              {t('shop')}
            </p>
            <div className={cn(styles.desktop, styles.wrapperLists)}>
              <ul className={styles.list}>
                {shopItemData.slice(0,5).map((item)=> {
                  return (
                      <>
                        <li key={item.id} className={styles.item}>
                          <Link href={item.link}>
                            <a className={styles.navLink}>{item.title}</a>
                          </Link>
                        </li>
                      </>
                  )
                })}
              </ul>
              <ul className={styles.list}>
                {shopItemData.slice(5,10).map((item)=> {
                  return (
                      <>
                        <li key={item.id} className={styles.item}>
                          <Link href={item.link}>
                            <a className={styles.navLink}>{item.title}</a>
                          </Link>
                        </li>
                      </>
                  )
                })}
              </ul>
            </div>
            {width < 1024 && openBlock
                ? <div className={cn(styles.wrapperLists, {[styles.openBlock]:openBlock===true})}>
              <ul className={styles.list}>
                {shopItemData.slice(0,5).map((item)=> {
                  return (
                      <>
                        <li key={item.id} className={styles.item}>
                          <Link href={item.link}>
                            <a className={styles.navLink}>{item.title}</a>
                          </Link>
                        </li>
                      </>
                  )
                })}
              </ul>
              <ul className={styles.list}>
                {shopItemData.slice(5,10).map((item)=> {
                  return (
                      <>
                        <li key={item.id} className={styles.item}>
                          <Link href={item.link}>
                            <a className={styles.navLink}>{item.title}</a>
                          </Link>
                        </li>
                      </>
                  )
                })}
              </ul>
            </div>
                : null
            }
          </div>
          <div className={cn(styles.containerBlock, styles.desktop)}>
            <div className={styles.navNew}>
              <p className={cn(styles.titleBlock, styles.widthTitle)}>Customer Services</p>
              <ul className={styles.list}>
                {customerItemData.map((item)=> {
                  return (
                      <>
                        <li key={item.id} className={styles.item}>
                          <Link href={item.link}>
                            <a className={styles.navLink}>{item.title}</a>
                          </Link>
                        </li>
                      </>
                  )
                })}
              </ul>
            </div>
            <div className={styles.navNew}>
              <p className={styles.titleBlock}>About</p>
              <ul className={styles.list}>
                {aboutItemData.map((item)=> {
                  return (
                      <>
                        <li key={item.id} className={styles.item}>
                          <Link href={item.link}>
                            <a className={styles.navLink}>{item.title}</a>
                          </Link>
                        </li>
                      </>
                  )
                })}
              </ul>
            </div>
          </div>

          <div className={cn(styles.containerBlock, styles.mobileNav)}>
            <div className={styles.navNew}>
              <p className={styles.titleBlock}>About</p>
              <ul className={styles.list}>
                {aboutItemData.map((item)=> {
                  return (
                      <>
                        <li key={item.id} className={styles.item}>
                          <Link href={item.link}>
                            <a className={styles.navLink}>{item.title}</a>
                          </Link>
                        </li>
                      </>
                  )
                })}
              </ul>
            </div>
            <div className={styles.navNew}>
              <p className={cn(styles.titleBlock, styles.widthTitle)}>Customer Services</p>
              <ul className={styles.list}>
                {customerItemData.map((item)=> {
                  return (
                      <>
                        <li key={item.id} className={styles.item}>
                          <Link href={item.link}>
                            <a className={styles.navLink}>{item.title}</a>
                          </Link>
                        </li>
                      </>
                  )
                })}
              </ul>
            </div>
          </div>

          <div className={styles.navItem}>
            <a href="mailto:support@vitaliv.us" className={cn(styles.emailTitle, styles.mobile)}>
              Email Us
            </a>
          </div>
          <div className={cn(styles.navNew, styles.desktop, styles.desktopLogo)}>
            <a href="/" target="_blank" className={styles.logo}>
              <span className={styles.logoClub}>
                <Image
                    src="/images/logoFooterNew.svg"
                    alt="VItaliv"
                    width={217}
                    height={50}
                    objectFit="contain"
                />
              </span>
            </a>
          </div>
        </div>

        <div className={styles.block}>
          <div className={cn(styles.navNew, styles.desktop)}>
            <p className={styles.titleBlock}>Follow us</p>
            <ul className={cn(styles.list, styles.listIcon)}>
              {followUsData.map((item)=> {
                return (
                    <>
                      <li key={item.id} className={styles.item}>
                        <a target="_blank" rel="noopener noreferrer" href={item.link}>
                          <Image
                              src={item.img}
                              alt="social"
                              width={40}
                              height={40}
                              objectFit="contain"
                          />
                        </a>
                      </li>
                    </>
                )
              })}
            </ul>
            <div className={styles.navItem}>
              <a href="mailto:support@vitaliv.us" className={styles.emailTitle}>
                Email Us
              </a>
            </div>
          </div>
          <div className={styles.navNew}>
            <ul className={cn(styles.list, styles.listApp)}>
              {appData.map((item)=> {
                return (
                    <>
                      <li key={item.id} className={styles.item}>
                        <a target="_blank" rel="noopener noreferrer" href={item.link}>
                          <Image
                              src={item.img}
                              alt="social"
                              width={172}
                              height={51}
                              objectFit="contain"
                          />
                        </a>
                      </li>
                    </>
                )
              })}
            </ul>
            <div className={cn(styles.navNew, styles.mobile, styles.mobileLogo)}>
              <a href="/" target="_blank" className={styles.logo}>
              <span className={styles.logoClub}>
                <Image
                    src="/images/logoFooterNew.svg"
                    alt="VItaliv"
                    width={144}
                    height={33}
                    objectFit="contain"
                />
              </span>
              </a>
            </div>
            <p className={cn(styles.text, styles.mobile, styles.mobileLogo)}>© 2022 Vitaliv AS</p>
            <ul className={cn(styles.list, styles.listApp, styles.listPolicy)}>
              <li>
                <p className={cn(styles.text, styles.desktop)}>© 2022 Vitaliv AS</p>
              </li>
              <li>
                <Link href="https://shop.vitaliv.us/policies/privacy-policy">
                  <a target="_blank" className={cn(styles.textPolicy, styles.textPolicyMobile)}>Privacy Policy</a>
                </Link>
              </li>
              <li>
                <Link href="https://shop.vitaliv.us/policies/terms-of-service">
                  <a target="_blank" className={styles.textPolicy}>Terms of Service</a>
                </Link>
              </li>
              <li>
                <Link href="https://shop.vitaliv.us/policies/refund-policy">
                  <a target="_blank" className={styles.textPolicy}>Refund policy</a>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.bottomLine}>
          <div className={styles.disclaimer}>† THESE STATEMENTS HAVE NOT BEEN EVALUATED BY THE
            FOOD AND DRUG ADMINISTRATION. THIS PRODUCT IS NOT INTENDED TO DIAGNOSE, TREAT,
            CURE, OR PREVENT ANY DISEASE.</div>
        </div>
      </div>

      <ModalWindow
        isOpened={disclaimerModalOpened}
        closeFunction={() => setDisclaimerModalOpened(false)}
      >
        <h3 className={styles.disclaimerModalTitle}>
          {t('vitaliv-all-rights-reserved')}
        </h3>
        <p>{t('vitaliv-all-rights-reserved-text-1')}</p>
        <p>{t('vitaliv-all-rights-reserved-text-2')}</p>
      </ModalWindow>
    </footer>
  )
}

export default FooterNew
