export const shopItemData = [
    {
        id: 0,
        title: 'Bone & Joints Health',
        link: 'https://shop.vitaliv.us/collections/bone-joints-health'
    },
    {
        id: 1,
        title: 'Digestion',
        link: 'https://shop.vitaliv.us/collections/digestion'
    },
    {
        id: 2,
        title: 'Weight management',
        link: 'https://shop.vitaliv.us/collections/weight-management'
    },
    {
        id: 3,
        title: 'Hair, Skin & Nails',
        link: 'https://shop.vitaliv.us/collections/hair-skin-nails'
    },
    {
        id: 4,
        title: 'Stress/ Mood/ Sleep',
        link: 'https://shop.vitaliv.us/collections/stress-mood-sleep'
    },
    {
        id: 5,
        title: 'Women\'s health',
        link: 'https://shop.vitaliv.us/collections/womens-health'
    },
    {
        id: 6,
        title: 'Men\'s health',
        link: 'https://shop.vitaliv.us/collections/mens-health'
    },
    {
        id: 7,
        title: 'Eye Health',
        link: 'https://shop.vitaliv.us/collections/eye-health'
    },
    {
        id: 8,
        title: 'Well-being',
        link: 'https://shop.vitaliv.us/collections/well-being'
    },
    {
        id: 9,
        title: 'Cardiovascular health',
        link: 'https://shop.vitaliv.us/collections/cardiovascular-health'
    },
]

export const customerItemData = [
    {
        id: 0,
        title: 'FAQs',
        link: '/faq'
    },
    {
        id: 1,
        title: 'Delivery & returns',
        link: '/delivery'
    },
    {
        id: 2,
        title: 'Contacts',
        link: '/contacts'
    },
]

export const aboutItemData = [
    {
        id: 0,
        title: 'Blog',
        link: 'https://blog.vitaliv.us'
    },
    {
        id: 1,
        title: 'About Us',
        link: '/aboutus'
    },
    {
        id: 2,
        title: 'Vitaliv Club',
        link: '/vitalivclub'
    },
]

export const followUsData = [
    {
        id: 0,
        img: '/images/FB1.svg',
        link: 'https://facebook.com/vitaliv.club'
    },
    {
        id: 1,
        img: '/images/INST1.svg',
        link: 'https://www.instagram.com/vitaliv.club/'
    },
]

export const appData = [
    {
        id: 0,
        img: '/images/appStoreFooter.png',
        link: 'https://apps.apple.com/us/app/vitaliv-club/id1611704943'
    },
    {
        id: 1,
        img: '/images/googlePlayFooter.png',
        link: 'https://play.google.com/store/apps/details?id=us.vitaliv'
    },
]